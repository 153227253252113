import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from "@material-tailwind/react";
import OpeningHours from "../src/components/OpeningHours"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
    <OpeningHours />
      <Router> {/* Ensure Router wraps App */}
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);