import React from "react";

const Map = () => {
  return (
    <div className="relative h-96 w-auto">
      {/* Google Maps Iframe */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d595.2266314053321!2d17.491681775461565!3d53.362827775004426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25492b9f82093b91%3A0xcd76f560efa30146!2squbit%20%7C%20Software%20house%20%7C%20Strony%20Internetowe%20%7C%20Oprogramowanie!5e0!3m2!1spl!2spl!4v1730993535312!5m2!1spl!2spl"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps Location"
      ></iframe>
      {/* Overlay with filter effect */}
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-none "
        style={{
          backgroundColor: "#d7b48a88", // #D7B48A with 30% opacity
          mixBlendMode: "multiply", // Blend mode to mix the color
        }}
      ></div>
    </div>
  );
};

export default Map;
