import React from 'react'
import Kalendarz from '../components/Kalendarz'

const BezplatneKonsultacje = () => {
  return (
    <div>
      <Kalendarz />
    </div>
  )
}

export default BezplatneKonsultacje
