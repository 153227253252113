import React, { useState } from "react";
import Modal from "../components/Modal";
import Header from "../components/Header";
import wycenaHero from "../static/images/heroBanner.webp";

const Wycena = () => {
  const [formData, setFormData] = useState({
    email: "",
    companyName: "",
    website: "",
    interest: [],
    description: "",
    goal: "",
    features: "",
    pagesCount: "",
    pageDetails: "",
    pageColors: "",
    languages: "",
    existingAssets: [],
    completionDate: "",
    exampleLinks: "",
    websiteManagement: "",
    logo: "",
    budget: "",
    referralSource: "",
    inquiryStage: "",
    additionalInfo: "",
  });

  const [formStatus, setFormStatus] = useState(""); // Status for form submission
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShopSelected, setIsShopSelected] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const updatedInterest = checked
      ? [...formData[name], value]
      : formData[name].filter((item) => item !== value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedInterest,
    }));

    // Aktualizacja stanu dla 'Sklep'
    setIsShopSelected(updatedInterest.includes("Sklep"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsModalVisible(true);
    setFormStatus("Wysyłanie...");

    const form = new FormData();
    form.append("access_key", "26f27a07-d780-432d-96cb-ed85293d09d3"); // Your Web3Forms key
    form.append("email", formData.email);
    form.append("companyName", formData.companyName);
    form.append("website", formData.website);
    form.append("interest", formData.interest.join(", "));
    form.append("description", formData.description);
    form.append("goal", formData.goal);
    form.append("features", formData.features);
    form.append("pagesCount", formData.pagesCount);
    form.append("pageDetails", formData.pageDetails);
    form.append("pageColors", formData.pageColors);
    form.append("languages", formData.languages);
    form.append("existingAssets", formData.existingAssets.join(", "));
    form.append("completionDate", formData.completionDate);
    form.append("exampleLinks", formData.exampleLinks);
    form.append("websiteManagement", formData.websiteManagement);
    form.append("logo", formData.logo);
    form.append("budget", formData.budget);
    form.append("referralSource", formData.referralSource);
    form.append("inquiryStage", formData.inquiryStage);
    form.append("additionalInfo", formData.additionalInfo);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: form,
      });

      const data = await response.json();

      if (data.success) {
        setFormStatus("Wycena wysłana pomyślnie!");
        setFormData({
          email: "",
          companyName: "",
          website: "",
          interest: [],
          description: "",
          goal: "",
          features: "",
          pagesCount: "",
          pageDetails: "",
          pageColors: "",
          languages: "",
          existingAssets: [],
          completionDate: "",
          exampleLinks: "",
          websiteManagement: "",
          logo: "",
          budget: "",
          referralSource: "",
          inquiryStage: "",
          additionalInfo: "",
        });
        setTimeout(() => {
          setIsModalVisible(false);
          setFormStatus("");
        }, 4000);
      } else {
        setFormStatus("Error: " + data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormStatus("Error: " + error.message);
    }
  };

  return (
    <div className="bg-lightBeige text-lg">
      <Header title="Wycena" imageUrl={wycenaHero} />
      <p
        id="next-section"
        className="py-10 mx-[1rem] md:mx-auto text-center md:w-[60vw]"
      >
        Każda strona internetowa to indywidualna wizja, która wymaga wyjątkowego
        podejścia. Tworząc ją, skupiamy się nie tylko na wyglądzie, ale także na
        funkcjonalności, dzięki czemu Twoja strona staje się narzędziem do
        osiągania celów. Zanim jednak zaczniemy naszą wspólną podróż, ważne
        jest, aby precyzyjnie określić zakres i potrzeby Twojego projektu.
        Poniżej znajdziesz kluczowe informacje, które pomogą nam przygotować
        dokładną wycenę Twojej wymarzonej strony internetowej.
      </p>
      <div className="max-w-3xl mx-auto p-6 py-10">
        <form onSubmit={handleSubmit} className="space-y-4 items-center">
          <div>
            <label className="font-medium">
              Twój adres email*:
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>

          <div>
            <label className="font-medium">
              Nazwa Twojej firmy:
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>

          <div>
            <label className="font-medium">
              Podaj adres obecnej strony i linki do mediów społecznościowych:
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>
          <div>
            <label className="font-medium">
              Czy posiadasz już logo swojej firmy?*:
              <select
                name="hasLogo"
                required
                value={formData.hasLogo}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              >
                <option value="">Wybierz</option>
                <option value="Tak, mam gotowe logo">
                  Tak, mam gotowe logo
                </option>
                <option value="Nie, potrzebuję zaprojektować logo">
                  Nie, potrzebuję zaprojektować logo
                </option>
                <option value="Logo jest w przygotowaniu">
                  Logo jest w przygotowaniu
                </option>
              </select>
            </label>
          </div>
          <div>
            <label className="font-medium">
              Interesuje Cię*:
              <div className="mt-2 space-y-2">
                {["Sklep", "Blog", "Landing Page", "Portfolio"].map((item) => (
                  <label
                    key={item}
                    className="flex items-center space-x-2 text-sm"
                  >
                    <input
                      type="checkbox"
                      name="interest"
                      value={item}
                      onChange={handleCheckboxChange}
                      className="form-checkbox focus:border-gold"
                    />
                    <span>{item}</span>
                  </label>
                ))}
              </div>
            </label>
          </div>
          {isShopSelected && (
          <>
            <div>
              <label className="font-medium">
                Jakie metody płatności mają być obsługiwane?:
                <input
                  type="text"
                  name="paymentMethods"
                  value={formData.paymentMethods || ""}
                  onChange={handleChange}
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
                />
              </label>
            </div>

            <div>
              <label className="font-medium">
                Ile produktów planujesz wprowadzić na start?:
                <input
                  type="number"
                  name="productCount"
                  value={formData.productCount || ""}
                  onChange={handleChange}
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
                />
              </label>
            </div>

            <div>
              <label className="font-medium">
                Czy potrzebujesz integracji z systemami magazynowymi lub ERP?*:
                <select
                  name="erpIntegration"
                  value={formData.erpIntegration || ""}
                  onChange={handleChange}
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
                >
                  <option value="">Wybierz</option>
                  <option value="Tak">Tak</option>
                  <option value="Nie">Nie</option>
                </select>
              </label>
            </div>
            <div>
              <label className="font-medium">
                Czy potrzebujesz modułu bloga lub selekcji "opinie klientów"?*:
                <select
                  name="erpIntegration"
                  value={formData.erpIntegration || ""}
                  onChange={handleChange}
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
                >
                  <option value="">Wybierz</option>
                  <option value="Tak">Tak</option>
                  <option value="Nie">Nie</option>
                </select>
              </label>
            </div>
          </>
        )}
          <div>
            <label className="font-medium">
              Czy masz określoną paletę kolorów, którą chciałbyś zastosować na
              stronie?*:
              <textarea
                name="colorPalette"
                value={formData.colorPalette}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              ></textarea>
            </label>
          </div>
          <div>
            <label className="font-medium">
              Jakie funkcje marketingowe są dla Ciebie ważne?*(np kody rabatowe, promocje):
              <textarea
                name="colorPalette"
                value={formData.colorPalette}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              ></textarea>
            </label>
          </div>
          <div>
            <label className="font-medium">
              Jakie funkcje nawigacyjne chcesz uwzględnić na swojej stronie?*:
              <input
                type="text"
                name="navigation"
                value={formData.navigation}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>
          <div>
            <label className="font-medium">
              Opisz swoją działalność lub produkt*:
              <textarea
                name="description"
                required
                value={formData.description}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              ></textarea>
            </label>
          </div>

          <div>
            <label className="font-medium">
              Jaki ma być główny cel strony?*:
              <input
                type="text"
                name="goal"
                required
                value={formData.goal}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>

          <div>
            <label className="font-medium">
              Jakich funkcjonalności oczekujesz na stronie?:
              <input
                type="text"
                name="features"
                value={formData.features}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>

          <div>
            <label className="font-medium">
              Szacowana liczba podstron*:
              <select
                name="pagesCount"
                required
                value={formData.pagesCount}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              >
                <option value="">Wybierz</option>
                <option value="Maksymalnie 5">Maksymalnie 5</option>
                <option value="6 do 10">6 do 10</option>
                <option value="11 do 19">11 do 19</option>
                <option value="Nie wiem">Nie wiem</option>
              </select>
            </label>
          </div>
          <div>
            <label className="font-medium">
              Czy masz przykłady stron, które Ci się podobają? Podaj linki:
              <textarea
                name="exampleWebsites"
                value={formData.exampleWebsites}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              ></textarea>
            </label>
          </div>
          <div>
            <label className="font-medium">
              Czy masz preferencje co do technologii, na której powinna być
              oparta strona (np. WordPress, React)?:
              <input
                type="text"
                name="preferredTechnology"
                value={formData.preferredTechnology}
                onChange={handleChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-gold"
              />
            </label>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full p-3 bg-gold text-darkGray hover:text-white transition-all duration-300 ease-in-out rounded-lg"
            >
              Wyślij wycenę
            </button>
          </div>
        </form>

        {/* Modal for form status */}
        {isModalVisible && <Modal formStatus={formStatus} />}

       
      </div>
    </div>
  );
};

export default Wycena;
