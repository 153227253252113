import React from "react";
import { Link as ScrollLink } from "react-scroll"; // Importujemy ScrollLink

const Header = ({ title, imageUrl }) => {
  return (
    <div
      className="relative flex flex-col items-center justify-center h-[100vh] md:h-[80vh] text-center text-lightOlive"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Overlay covering the entire header */}
      <div className="absolute inset-0 bg-black opacity-50 backdrop-filter backdrop-blur-sm"></div>{" "}
      {/* Dodano blur do overlay i z-index */}
      {/* Instagram Vertical Line and Text */}
      <div className="absolute right-[2rem] lg:right-[6rem] top-[50%] transform -translate-y-[50%] flex flex-col items-center">
        {/* Vertical Line */}
        <div className="h-[30vh] md:h-[50vh] border-l-2 border-gold flex flex-col justify-center items-center relative">
          {/* Instagram text */}
          <p className="text-gold m-2 md:m-4 text-base absolute top-1/2 left-0 transform -translate-x-[50%] -translate-y-[50%] rotate-90 origin-center">
            <a
              href="https://www.instagram.com/qubit.soft/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </p>
        </div>
      </div>
      {/* Content container with text and button */}
      <div className="relative flex flex-col items-center justify-center p-10">
        {/* Nagłówek wyśrodkowany w pionie */}
        <h1 className="text-[2.5rem] md:text-[3rem] font-thin text-lightOlive mb-4">
          {title}
        </h1>

        {/* Button to scroll to the next section */}
        <div className="mt-6">
          <ScrollLink
            to="next-section" // ID sekcji, do której przewijamy
            smooth={true}
            duration={500}
            className="text-darkGray bg-gold hover:text-white px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out cursor-pointer"
          >
            Przewiń w dół
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
