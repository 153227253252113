import React, { useState } from "react";
import office from "../static/images/office.webp";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    projectType: "",
    privacyPolicy: false,
  });

  const [formStatus, setFormStatus] = useState("");
  const [isPrivacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Sending...");

    const form = new FormData(e.target);
    form.append("access_key", "d4aa97b0-b960-44a1-9907-d89aa75ee63e");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",

        body: form,
      });

      const data = await response.json();

      if (data.success) {
        setFormStatus("Wiadomość wysłana!");
        e.target.reset();
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          projectType: "",
          privacyPolicy: false,
        });
        setTimeout(() => {
          setFormStatus("");
        }, 4000);
      } else {
        setFormStatus("Error: " + data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormStatus("Error: " + error.message);
    }
  };
  const handleCloseModal = () => {
    setPrivacyPolicyVisible(false);
  };
  const togglePrivacyPolicy = () => {
    setPrivacyPolicyVisible((prev) => !prev);
  };

  return (
    <div
      id="next-section"
      className="flex flex-col md:flex-row items-stretch max-w-8xl mx-auto p-4 md:p-8"
    >
      {/* Miejsce na zdjęcie po lewej stronie */}
      <div className="w-full md:w-1/2 pr-0 md:pr-8 flex flex-col justify-between mb-8 md:mb-0">
        <div className="bg-darkGray border border-gold flex flex-col px-4 py-4 md:px-8 md:py-6 mb-4">
          <p className="text-lightOlive text-base md:text-lg">Kontakt</p>
          <p className="text-lightOlive text-base md:text-lg">
            Jakub Klonowski-Rospłoch
          </p>
          <p className="text-lightOlive text-base md:text-lg">
            Adrianna Lenczewska
          </p>
          <p className="text-lightOlive text-base md:text-lg">info@qubity.pl</p>
          <p className="text-lightOlive text-base md:text-lg">
            ul. Gdańska, Więcbork
          </p>
          <p className="text-lightOlive text-base md:text-lg"></p>
          <p className="text-lightOlive text-base md:text-lg">690363690</p>
          <p className="text-lightOlive text-base md:text-lg">669961266</p>
        </div>
        <img src={office} alt="Office" className="w-full h-auto object-cover" />
      </div>

      {/* Formularz po prawej stronie */}
      <div className="w-full md:w-1/2 bg-darkGray p-4 md:p-8 border border-gold flex flex-col justify-between min-h-[400px] md:min-h-[500px]">
        <h2 className="text-xl md:text-2xl lg:text-3xl mb-4 md:mb-6 text-center text-lightOlive">
          Skontaktuj się z nami
        </h2>
        <p className="text-center mb-4 text-lightOlive text-sm md:text-base">
          {formStatus}
        </p>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-between h-full"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6">
            <div>
              <label
                className="block text-sm md:text-base font-medium mb-2 text-lightOlive"
                htmlFor="name"
              >
                Imię
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gold bg-darkGray text-lightOlive focus:outline-none focus:ring-2 focus:ring-gold focus:border-gold text-sm md:text-base"
                required
              />
            </div>
            <div>
              <label
                className="block text-sm md:text-base font-medium mb-2 text-lightOlive"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gold bg-darkGray text-lightOlive focus:outline-none focus:ring-2 focus:ring-gold focus:border-gold text-sm md:text-base"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6">
            <div>
              <label
                className="block text-sm md:text-base font-medium mb-2 text-lightOlive"
                htmlFor="phone"
              >
                Numer Telefonu
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gold bg-darkGray text-lightOlive focus:outline-none focus:ring-2 focus:ring-gold focus:border-gold text-sm md:text-base"
              />
            </div>

            {/* Nowe pole wyboru */}
            <div>
              <label
                className="block text-sm md:text-base font-medium mb-2 text-lightOlive"
                htmlFor="projectType"
              >
                Rodzaj projektu
              </label>
              <select
                id="projectType"
                name="projectType"
                value={formData.projectType}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gold bg-darkGray text-lightOlive focus:outline-none focus:ring-2 focus:ring-gold focus:border-gold text-sm md:text-base"
                required
              >
                <option value="">Rodzaj projektu</option>
                <option value="sklep internetowy">Sklep Internetowy</option>
                <option value="strona www">Strona WWW</option>
                <option value="aplikacja mobilna">Aplikacja Mobilna</option>
              </select>
            </div>
          </div>

          <div className="mb-4 md:mb-6">
            <label
              className="block text-sm md:text-base font-medium mb-2 text-lightOlive"
              htmlFor="message"
            >
              Wiadomość
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gold bg-darkGray text-lightOlive focus:outline-none focus:ring-2 focus:ring-gold focus:border-gold text-sm md:text-base"
              rows="4"
              required
            ></textarea>
          </div>

          <div className="flex items-start mb-4 md:mb-6">
            <input
              type="checkbox"
              id="privacyPolicy"
              name="privacyPolicy"
              checked={formData.privacyPolicy}
              onChange={handleChange}
              className="w-5 h-5 text-darkGray bg-gray-100 border-gray-300 focus:ring-darkGray"
              required
            />
            <label
              htmlFor="privacyPolicy"
              className="ml-3 text-sm md:text-base text-lightOlive"
            >
              Akceptuję{" "}
              <button
                type="button"
                onClick={togglePrivacyPolicy}
                className="text-lightOlive underline"
              >
                politykę prywatności
              </button>
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-2 md:py-3 bg-gold hover:text-white text-darkGray font-semibold transition-colors duration-300"
          >
            Wyślij wiadomość
          </button>
        </form>
      </div>

      {/* Modal Polityki Prywatności */}
      {isPrivacyPolicyVisible && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-darkGray bg-opacity-50 z-50 p-4"
          onClick={handleCloseModal} // Kliknięcie poza modalem zamyka modal
        >
          <div
            className="bg-darkGray border text-base border-gold text-lightOlive p-6 rounded-lg max-w-full w-full sm:max-w-lg sm:p-8 overflow-y-auto max-h-[90vh]"
            onClick={(e) => e.stopPropagation()} // Zatrzymanie propagacji kliknięcia wewnątrz modala
          >
            <p className="mb-4">Polityka Prywatności</p>
            <p className="mb-4">
              I. Postanowienia ogólne
              <br />
              Administratorem Pani/Pana* danych osobowych jest qubit Jakub
              Klonowski-Rospłoch, ul. Gdańska 31, 89-410 Więcbork. Niniejsza
              Polityka Prywatności określa zasady przetwarzania danych osobowych
              użytkowników korzystających z formularza kontaktowego dostępnego
              na stronie www.qubity.pl
            </p>
            <p className="mb-4">
              II. Administrator danych
              <br />
              Administrator wyznaczył Inspektora Ochrony Danych z z którym może
              Pani/Pan* się skontaktować w sprawach ochrony danych osobowych i
              realizacji swoich praw pod następującym adresem e-mail:
              info@qubity.pl lub pisemnie na dane podane w pkt.1
            </p>
            <p className="mb-4">
              III. Cel zbierania danych osobowych
              <br />
              Dane osobowe, takie jak imię i adres e-mail, są zbierane wyłącznie
              w celu kontaktu z użytkownikiem na podstawie przesłanego
              formularza.
            </p>
            <p className="mb-4">
              IV. Rodzaj przetwarzanych danych osobowych
              <br />
              Przetwarzane dane to imię oraz adres e-mail podany przez
              użytkownika w formularzu kontaktowym.
            </p>
            <p className="mb-4">
              V. Okres przetwarzania danych osobowych
              <br />
              Dane osobowe będą przetwarzane przez okres niezbędny do realizacji
              celu, w jakim zostały zebrane, lub do momentu wycofania zgody
              przez użytkownika.
            </p>
            <p className="mb-4">
              VI. Prawa użytkowników
              <br />
              Użytkownicy mają prawo dostępu do swoich danych, ich sprostowania,
              usunięcia, ograniczenia przetwarzania oraz przenoszenia danych.
              Mogą również w każdej chwili wycofać zgodę na przetwarzanie
              danych, co nie wpłynie na zgodność z prawem przetwarzania przed
              jej wycofaniem. Wszelkie zgłoszenia należy kierować na adres
              e-mail: info@qubity.pl
            </p>
            <p className="mb-4">
              VII. Postanowienia końcowe
              <br />
              Administrator zastrzega sobie prawo do wprowadzenia zmian w
              niniejszej Polityce Prywatności. Wszelkie zmiany będą publikowane
              na tej stronie.
            </p>
            <button
              onClick={handleCloseModal}
              className="w-full bg-lightCoral text-lightOlive border border-gold p-3 rounded-lg font-bold hover:text-gold hover:bg-darkGray transition duration-300 mt-4"
            >
              Zamknij
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
