import React from "react";

const Instagram = () => {
  return (
    <div style={{ padding: "20px", backgroundColor: "#f0f0f0" }}>
      <h1>TEST</h1>
    </div>
  );
};

export default Instagram;
