import React from "react";

const OpeningHours = () => {
  // Get current date and time
  const now = new Date();
  const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
  const hours = now.getHours(); // Current hour (0 to 23)

  // Define opening hours
  const weekdaysOpen = hours >= 8 && hours < 18 && dayOfWeek >= 1 && dayOfWeek <= 5; // Mon-Fri
  const saturdayOpen = hours >= 10 && hours < 15 && dayOfWeek === 6; // Saturday
  const isOpen = weekdaysOpen || saturdayOpen;

  return (

    !isOpen && (
    <div className="flex justify-center items-center bg-lightBeige p-2 w-full">
      <p className="text-darkGray text-sm font-semibold text-center">
      </p>
      <p className="text-darkGray text-sm ml-4 text-center">
        Pon-pt: 8:00 - 18:00 | Sob: 10:00 - 15:00 | Nd: Zamknięte
      </p>
    </div>
    )
  );
};

export default OpeningHours;
