import mockupImage from "../static/images/mockup.webp"; // Upewnij się, że ścieżka jest poprawna
import laptop from "../static/images/laptop.webp";
import tablet from "../static/images/office2.webp";
import bgImageOffice from "../static/images/bgImageOffice.webp";
import ada from "../static/images/ada.svg";
import kuba from "../static/images/kuba.svg";
import sandra from "../static/images/sandra.svg";
import writing from "../static/images/writing.webp";
import laptopp from "../static/images/office1.webp";
import planer from "../static/images/planer.webp";
import { FaServer, FaClock, FaClipboardList } from "react-icons/fa";

export const navLinks = [
  {
    id: "StronaGlowna",
    title: "Strona Główna",
    path: "/",
  },
  {
    id: "O-nas",
    title: "O nas",
    path: "/o-nas",
  },
  {
    id: "Oferta",
    title: "Oferta",
    path: "/oferta",
  },
  {
    id: "Wycena",
    title: "Wycena",
    path: "/wycena",
  },
  {
    id: "Kontakt",
    title: "Kontakt",
    path: "/kontakt",
  },
];

export const blocksData = [
  {
    title: "Strona internetowa",
    description:
      "Tworzymy nowoczesne i responsywne strony internetowe, które przyciągają uwagę i angażują użytkowników. Twoja witryna będzie wyglądać świetnie na każdym urządzeniu.",
    link: "/oferta",
  },
  {
    title: "Sklep internetowy",
    description:
      "Zbuduj swój sklep online z łatwością. Nasze rozwiązania e-commerce są intuicyjne, bezpieczne i zoptymalizowane, aby zwiększyć Twoją sprzedaż.",
    link: "/sklep-internetowy",
  },
  {
    title: "Gotowe Szablony",
    description:
      "Chcesz szybko wystartować ze swoją stroną internetową, nie tracąc przy tym na jakości? Już niedługo w naszej ofercie pojawią się gotowe szablony. Dzięki nim zyskasz profesjonalną stronę w przystępnej cenie, dostosowaną do Twoich potrzeb.",
    link: "/gotowe-szablony",
  },
];

export const employees = [
  {
    name: "Kuba",
    position: "Fullstack",
    description: `  Doświadczony programista Full Stack, tworzący niesamowite i innowacyjne produkty. 
                    Specjalizuje się w technologii Microsoft, a jego głowa pełna jest nowoczesnych pomysłów, 
                    co sprawia, że nieustannie poszukuje sposobów na ulepszanie zarówno kodu, jak i otaczających go procesów. 
                    Żaden projekt nie stanowi dla niego wyzwania, 
                    niezależnie od etapu, na którym się znajduje. Zawsze daje z siebie wszystko i potrafi znaleźć rozwiązanie każdego napotkanego problemu.`,
    experience: "4+",
    backend: "50%",
    frontend: "",
    timeManagement: "20%",
    github: "https://github.com/JakubRoss",
    linkedin: "https://www.linkedin.com/in/jakubross/",
    image: kuba,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
  {
    name: "Ada",
    position: "Webdeveloper",
    description: ` Ambitna Web Developerka oraz UI/UX Designerka, którą fascynuje świat technologii i designu.
                   Jej doświadczenie pozwoliło jej rozwinąć umiejętności w zakresie kodowania oraz projektowania,
                   a determinacja i pasja napędzają ją do tworzenia innowacyjnych, użytecznych i estetycznych rozwiązań online.`,
    experience: "1+",
    frontend: "80%",
    timeManagement: "20%",
    github: "https://github.com/AdriannaLen",
    linkedin: "https://www.linkedin.com/in/adrianna-lenczewska-276185287/",
    image: ada,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
  {
    name: "Sandra",
    position: "Webdeveloper",
    description: `  Kreatywna dusza, od zawsze zafascynowana tworzeniem pięknych i funkcjonalnych rozwiązań. 
                    Po ukończeniu SDA, skoncentrowała się na rozwijaniu umiejętności w zakresie Frontend Developmentu i Graficznego Designu, 
                    łącząc estetykę z najlepszymi praktykami programistycznymi.`,
    experience: "1+",
    frontend: "80%",
    timeManagement: "20%",
    github: "https://github.com/SandraRosploch",
    linkedin: "https://www.linkedin.com/in/sandra-rosploch/",
    image: sandra,
    backendIcon: <FaServer />,
    timeManagementIcon: <FaClock />,
    timeIcon: <FaClipboardList />,
  },
];
export const projects = [
  { image: mockupImage },
  { image: mockupImage },
  { image: mockupImage },
  { image: mockupImage },
];
export const blocks = [
  {
    title: "Landing Page",
    description:
      "Idealne rozwiązanie, jeśli potrzebujesz jednej, dobrze zoptymalizowanej strony, która spełnia konkretne cele – np. zbieranie leadów, promocję nowego produktu lub usługi.",
    benefits: [
      "Szybki czas realizacji",
      "Skupienie na jednej ofercie",
      "Optymalizacja pod kątem konwersji",
    ],
    scope: [
      "Konsultacja i analiza celów",
      "Wybór motywu lub unikalny projekt",
      "Implementacja treści, obrazów i formularzy",
      "Optymalizacja SEO",
      "Testowanie responsywności",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Strona Wielostronicowa (Multi-Page)",
    description:
      "Rozbudowana strona z wieloma podstronami, idealna dla firm potrzebujących pełnej prezentacji oferty.",
    benefits: [
      "Pełna prezentacja oferty",
      "Łatwa nawigacja",
      "Lepsze pozycjonowanie SEO",
    ],
    scope: [
      "Konsultacja i analiza potrzeb",
      "Tworzenie mapy strony",
      "Projektowanie i dostosowanie motywu",
      "Integracja z blogiem i galerią",
      "Optymalizacja SEO",
      "Testowanie responsywności",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Sklep Internetowy (E-commerce)",
    description: "Nowoczesny sklep internetowy, z pełną integracją płatności.",
    benefits: [
      "Sprzedaż online",
      "Zarządzanie produktami",
      "Automatyzacja procesów sprzedaży",
    ],
    scope: [
      "Konsultacja i analiza rynku",
      "Wybór i dostosowanie motywu e-commerce",
      "Konfiguracja katalogu produktów i płatności",
      "Integracja z bramkami płatniczymi",
      "Konfiguracja automatycznych e-maili",
      "Szkolenie z obsługi sklepu",
      "Testowanie i optymalizacja",
      "Opcja dodatkowego języka tłumaczonego przez tłumacza przysięgłego",
      "Polityka prywatności i pliki cookies",
    ],
  },
  {
    title: "Opcja Wielojęzyczności",
    description:
      "Stworzenie strony w wielu językach, idealne dla firm działających międzynarodowo.",
    benefits: [
      "Zwiększenie zasięgu w różnych krajach",
      "Poprawa SEO w różnych wersjach językowych",
      "Profesjonalny wygląd i intuicyjna nawigacja",
    ],
    scope: [
      "Tłumaczenie treści",
      "Konfiguracja narzędzia do zarządzania językami",
      "Dodanie przycisków przełączania języka",
      "Testowanie poprawności wersji językowych",
      "Polityka prywatności i pliki cookies",
    ],
  },
];
export const items = [
  {
    feature: "Czas realizacji",
    basic: "2 tygodnie",
    advanced: "4 tygodnie",
    premium: "6 tygodni",
  },
  { feature: "Strona główna", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "Kontakt", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "O nas", basic: "✔", advanced: "✔", premium: "✔" },
  { feature: "Blog", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Galeria", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Formularz kontaktowy", basic: "✘", advanced: "✔", premium: "✔" },
  { feature: "Sklep online", basic: "✘", advanced: "✘", premium: "✔" },
  { feature: "Wiele języków", basic: "✘", advanced: "✘", premium: "✔" },
  { feature: "Custom design", basic: "✘", advanced: "✘", premium: "✔" },
  {
    feature: "Zaawansowana personalizacja",
    basic: "✘",
    advanced: "✘",
    premium: "✔",
  },
  {
    feature: "Pełna optymalizacja SEO",
    basic: "✘",
    advanced: "✘",
    premium: "✔",
  },
  { feature: "Analiza i raporty", basic: "✘", advanced: "✘", premium: "✔" },
  {
    feature: "Wsparcie",
    basic: "Bez limitu",
    advanced: "Bez limitu",
    premium: "Bez limitu",
  },
  { feature: "Poprawki", basic: "2", advanced: "4", premium: "6" },
  {
    feature: "Cena",
    basic: "od 1500 PLN*",
    advanced: "od 2300 PLN*",
    premium: "od 5000 PLN*",
  },
];

export const kroki = [
  {
    tytul: "Konsultacja i Ustalenie Potrzeb",
    opis: "Rozmawiamy o Twojej wizji i potrzebach, aby dokładnie zrozumieć, jakie cele ma spełniać strona. Wspólnie analizujemy, kto będzie jej odbiorcą i jakie dodatkowe wymagania mogą być potrzebne, np. wersja wielojęzyczna. Na tym kroku poprosimy Cię o wypełnienie formularza.",
    zdjecie: planer,
    className: "rounded-tl-lg rounded-br-lg",
  },
  {
    tytul: "Planowanie i Przygotowanie Wyceny",
    opis: "Na podstawie ustaleń przygotowujemy szczegółową wycenę, sprawdzamy niezbębne dokumenty potrzebne, by strona była zgodna z przepisami (typu pliki cookie), podpisujemy umowę i budujemy projekt graficzny na bazie, którego napiszemy Twoją stronę.",
    zdjecie: tablet,
    className: "rounded-tr-full",
  },
  {
    tytul: "Projektowanie i Budowa Strony",
    opis: "Przedstawiamy projekt graficzny, a następnie kodujemy stronę. Instalujemy wszystkie niezbędne elementy, dodajemy teksty i obrazy oraz – jeśli to konieczne – przygotowujemy wersje językowe. W tym etapie dbamy o to, aby strona była szybka, przyjazna na telefonach i zgodna z przepisami.",
    zdjecie: laptop,
    className: "rounded-lg",
  },
  {
    tytul: "Testowanie i Sprawdzenie Funkcjonalności",
    opis: "Testujemy stronę na różnych urządzeniach i przeglądarkach, aby mieć pewność, że działa bez zarzutu. Sprawdzamy wszystkie wersje językowe oraz systemy płatności, jeśli są potrzebne, i upewniamy się, że wszystkie funkcje działają poprawnie.",
    zdjecie: laptopp,
    className: "rounded-tl-full",
  },
  {
    tytul: "Publikacja Strony",
    opis: "Strona jest gotowa do publikacji! Konfigurujemy domenę i hosting, uruchamiamy wszystkie wersje językowe i przeprowadzamy finalne testy, aby upewnić się, że wszystko działa bez zarzutu, w tym systemy płatności i polityka prywatności.",
    zdjecie: writing,
    className: "rounded-bl-full",
  },
  {
    tytul: "Szkolenie i Wsparcie Techniczne",
    opis: "Przekazujemy Ci wszystkie niezbędne informacje, jak zarządzać stroną – od dodawania nowych treści po obsługę płatności i wersji językowych. Zapewniamy także wsparcie techniczne na wypadek, gdybyś potrzebował pomocy po uruchomieniu strony.",
    zdjecie: bgImageOffice,
    className: "rounded-br-lg",
  },
];


export const opinie = [
  {
    name: "Sabina Glina",
    comment:
      '"Polecam firmę w 100% ,bardzo dobry kontakt oraz obsługa realizacji strony internetowej. Pełen profesjonalizm i zaangażowanie w wykonanie pracy. Jestem bardzo zadowolona. Dziękuję"',
    photo: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    name: "G Martin",
    comment:
      '"Skorzystałem z usługi i jestem bardzo zadowolony! Dzięki temu mogę być widoczny w internecie.Polecam serdecznie!"',
    photo: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    name: "Maciej Mampe",
    comment:
      '"Współpraca była bezproblemowa, a efekty pracy bardzo dobre. Profesjonalizm, terminowość a zaproponowane rozwiązania sprawiają, że z czystym sumieniem mogę polecić."',
    photo: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    name: "Katarzyna Lewandowska",
    comment:
      '"Usługa na najwyższym poziomie. Polecam"',
    photo: "https://randomuser.me/api/portraits/women/2.jpg",
  },
];


export const accordionData = [
  {
    title: "Ile kosztuje stworzenie strony internetowej?",
    content:
      "Koszt stworzenia strony internetowej zależy od kilku czynników, takich jak zakres funkcji, design, liczba podstron oraz zaawansowanie technologiczne. Oferujemy różne pakiety dostosowane do potrzeb – od podstawowego (od 1500 PLN) po zaawansowane rozwiązania premium (od 5000 PLN). Po omówieniu szczegółów projektu możemy dostosować ofertę, aby spełniała Twoje oczekiwania i budżet.",
  },
  {
    title: "Ile trwa zbudowanie strony internetowej?",
    content:
      "Czas realizacji zależy od stopnia zaawansowania strony. Pakiet podstawowy realizujemy w ciągu 2 tygodni, zaawansowany w 4 tygodnie, a premium w 6 tygodni. Terminy mogą się nieco wydłużyć, jeśli projekt wymaga dodatkowych funkcji lub personalizacji. Pracujemy jednak zawsze na podstawie uzgodnionych terminów, aby zapewnić terminową dostawę.",
  },
  {
    title: "Czy mogę samodzielnie zarządzać treściami na stronie? (CMS)",
    content:
      "Tak, strony, które tworzymy, opierają się na popularnych systemach CMS (jak WordPress), co umożliwia łatwą edycję treści, dodawanie zdjęć i aktualizowanie informacji bez konieczności posiadania umiejętności programistycznych. Po zakończeniu projektu zapewniamy krótkie szkolenie, które pozwoli Ci zarządzać stroną samodzielnie.",
  },
  {
    title: "Czy mogę później dodawać dodatkowe funkcje do mojej strony?",
    content:
      "Tak, strony internetowe, które budujemy, są elastyczne i łatwe do rozbudowy. Jeśli zdecydujesz się dodać bloga, galerię, dodatkowe języki czy sklep internetowy w przyszłości, możemy to zrobić bez konieczności przebudowy całej strony. Każdy projekt jest skalowalny, co pozwala na rozwijanie funkcjonalności zgodnie z potrzebami Twojej firmy.",
  },
  {
    title: "Czy strona będzie zoptymalizowana pod kątem SEO?",
    content:
      "Tak, optymalizacja SEO jest integralną częścią każdego projektu. Dbamy o to, by Twoja strona była widoczna w wynikach wyszukiwania Google poprzez odpowiednie dostosowanie treści, metadanych, szybkości ładowania oraz struktury strony. Pakiet premium obejmuje pełną optymalizację SEO, ale w każdym z naszych pakietów zadbamy o kluczowe aspekty optymalizacji.",
  },
];