import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import from React Router
import logo from '../static/images/GlassLogo.svg'; // Update the path to your logo

const TransitionOverlay = ({ isVisible, onTransitionEnd }) => {
  const [visible, setVisible] = useState(isVisible);
  
  // Efekt odpowiedzialny za animację overlay
  useEffect(() => {
    if (isVisible) {
      setVisible(true); // Pokaż overlay
    } else {
      const timer = setTimeout(() => setVisible(false), 500); // Ukryj overlay po animacji
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <div
      className={`overlay ${visible ? 'show' : 'hide'}`}
      onTransitionEnd={onTransitionEnd}
    >
      <div className="overlay-content">
        <img
          src={logo}
          alt="Logo"
          className="logo"
        />
      </div>
      <div className="text-gold text-3xl font-Gugi mt-6 tracking-widest">qubit <span className="font-Montserrat">SOFTWARE</span></div>
    </div>
  );
};

const AppWrapper = ({ children }) => {
  const location = useLocation(); // Dostęp do aktualnej trasy (route)
  const [loading, setLoading] = useState(true); // Stan ładowania

  // Efekt na początkowe ładowanie strony
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1500); // Ukryj overlay po 1.5 sekundy
    return () => clearTimeout(timer); // Wyczyść timer
  }, []);

  // Efekt, który wykrywa zmiany trasy
  useEffect(() => {
    setLoading(true); // Pokaż overlay przy zmianie trasy
    const timer = setTimeout(() => setLoading(false), 1500); // Ukryj overlay po 1.5 sekundy
    return () => clearTimeout(timer); // Wyczyść timer
  }, [location]); // Efekt jest wywoływany, kiedy zmienia się lokalizacja (trasa)

  return (
    <>
      <TransitionOverlay isVisible={loading} onTransitionEnd={() => setLoading(false)} />
      {/* Renderujemy dzieci tylko, jeśli overlay jest niewidoczny */}
      {!loading && children}
    </>
  );
};

export default AppWrapper;
