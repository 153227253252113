import React from 'react'
import Cennik from "../components/Cennik"


const Oferta = () => {
  return (
    <div>
     <Cennik />
    </div>
  )
}

export default Oferta
