import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import { navLinks } from "../constants";
import close from "../static/images/close.svg";
import menu from "../static/images/menu.svg";
import logo from "../static/images/GlassLogo.svg";

const Nav = ({ onNavigation }) => {
  const [toggle, setToggle] = React.useState(false);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const handleLinkClick = (path) => {
    onNavigation(path); // Trigger the custom navigation function
    if (toggle) {
      handleToggle(); // Close the menu if it's open
    }
  };

  return (
    <div className="absolute top-0 left-0 w-full z-50 mt-20">
      {" "}
      {/* Fixed on top with full width */}
      {/* Desktop Menu */}
      <div className="hidden md:flex align-middle flex-row justify-between items-center h-auto p-4 w-full max-w-[1200px] mx-auto">
        {" "}
        {/* Absolute positioning */}
        <a href="/">
          <img src={logo} alt="logo" className="w-[auto] h-[60px] ml-6 pl-6" />
        </a>
        <ul className="list-none flex justify-end items-center flex-1">
          {navLinks.map((nav) => (
            <li key={nav.id} className="mr-10 relative">
              <Link
                to={nav.path}
                onClick={() => handleLinkClick(nav.path)} // Use the new link click handler
                className="text-lg transition-colors duration-300 ease-in-out relative text-lightOlive" // Make text white
              >
                {nav.title}
                <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center justify-between w-full px-4 py-4 absolute">
        {" "}
        {/* Absolute positioning */}
        <a href="/">
          <img src={logo} alt="logo" className="h-[60px] m-6" />
        </a>
        {!toggle && (
          <img
            src={menu}
            alt="sidebar menu hamburger"
            className="w-[28px] h-[28px] object-contain cursor-pointer z-50"
            onClick={handleToggle}
          />
        )}
      </div>
      {/* Mobile Menu Dropdown */}
      <div
        className={`md:hidden fixed top-0 left-0 h-full w-[60vw] min-h-[100vh] bg-darkGray shadow-lg z-10 transition-transform duration-700 ease-in-out ${
          toggle ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex justify-end p-4">
          <img
            src={close}
            alt="close menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={handleToggle}
          />
        </div>
        <div className="flex flex-col items-center mt-4">
          <a href="/">
            <img src={logo} alt="logo qubit" className="h-[60px] m-6" />
          </a>
        </div>
        <ul className="list-none flex flex-col items-center py-4">
          {navLinks.map((nav) => (
            <li key={nav.id} className="my-4 relative">
              <Link
                to={nav.path}
                onClick={() => handleLinkClick(nav.path)} // Use the new link click handler
                className="text-lg transition-colors duration-300 ease-in-out relative text-white" // Make text white
              >
                {nav.title}
                <span className="absolute left-0 bottom-[-2px] w-full h-[2px] bg-gold transform scale-x-0 origin-left transition-transform duration-300 ease-in-out"></span>
              </Link>
            </li>
          ))}
          {/* Phone number with icon */}
          <li className="my-4 relative flex items-center text-white text-lg">
            <FontAwesomeIcon icon={faMobileAlt} className="mr-2 text-gold" />
            <a href="tel:+48690363690">690 363 690</a>
          </li>
          {/* Email with icon */}
          <li className="my-4 relative flex items-center text-white text-lg">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-gold" />
            <a href="mailto:info@qubity.pl">info@qubity.pl</a>
          </li>
        </ul>
      </div>
      {/* Overlay (Visible only when toggle is true) */}
      {toggle && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[5]" // Overlay background with 50% opacity
          onClick={handleToggle} // Close the menu when clicking outside the menu
        ></div>
      )}
    </div>
  );
};

export default Nav;
