import React from 'react'
import Kroki from "../components/Kroki"

const Proces = () => {
  return (
    <div>
      <Kroki />
        <div className="flex justify-center w-full bg-gold p-10">
        <a href="/BezplatneKonsultacje" className="text-darkGray hover:bg-gold hover:text-white border border-darkGray px-6 py-3 rounded-3xl text-xl font-extralight transition-all duration-300 ease-in-out">
          Zgłoś się na darmowe konsultacje !
        </a>
      </div>
    </div>
  )
}

export default Proces
