import React from "react";

const Modal = ({ message, isVisible, onClose }) => {
  return (
    isVisible && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-8 rounded shadow-lg text-center">
          <p className="text-lg text-darkOlive">{message}</p>
        </div>
      </div>
    )
  );
};

export default Modal;