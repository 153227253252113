import React from "react";
import { kroki } from "../constants/index";
import { motion } from "framer-motion";

const Kroki = () => {
  const stepVariants = {
    hidden: { opacity: 0, y: 50 }, // Początkowo niewidoczny i przesunięty w dół
    visible: { opacity: 1, y: 0 }, // Przesunięcie na miejsce i pojawienie się
  };

  return (
    <section className="bg-lightBeige">
      <h1 className="text-center text-lightOlive bg-darkGray px-2 text-2xl lg:text-3xl py-10">
        Jak działamy krok po kroku
      </h1>
      <div id="next-section" className="container mx-auto px-4">
        <div className="relative flex flex-col space-y-16">
          {kroki.map((krok, index) => (
            <motion.div
              key={index}
              className="step-wrapper"
              // Każdy krok ma swoje własne animacje
              variants={stepVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }} // Aktywuj animację po wejściu w viewport
              transition={{ duration: 0.5, delay: index * 0.2 }} // Płynniejsza animacja z opóźnieniem
            >
              {/* Linia pomiędzy krokami */}
              {index < kroki.length && (
                <div className="relative flex items-center justify-center my-14 sm:my-20">
                  <hr className="absolute top-1/2 left-0 right-0 border-t-4 border-gold" />
                  <span className="tracking-widest relative bg-lightBeige px-4 text-darkOlive text-3xl">
                    {`Krok ${index + 1}`}
                  </span>
                </div>
              )}
              <div
                className={`flex flex-col md:flex-row ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center space-y-8 md:space-y-0 md:space-x-8`}
              >
                <div className="flex-1 p-4 md:p-8">
                  <h3 className="text-xl md:text-2xl text-darkGray font-bold text-darkOlive mb-4">
                    {krok.tytul}
                  </h3>
                  <p className="text-base md:text-lg text-darkGray">
                    {krok.opis}
                  </p>
                </div>
                <div className="flex-1 py-4 flex items-center justify-center">
                  <img
                    src={krok.zdjecie}
                    alt={krok.tytul}
                    className={`w-auto h-auto max-h-80 md:max-h-120 krok-img ${krok.className}`}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Kroki;
