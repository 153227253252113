import React, { useState, useRef } from "react";
import { accordionData } from "../constants/index"

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]); // Reference to store the height of each content

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full lg:w-3/4 mx-auto px-4">
      <h1 className="text-center text-lightOlive bg-darkGray text-3xl py-10">FAQ</h1>
      {accordionData.map((item, index) => (
        <div
          key={index}
          className="mb-6 border border-gold rounded-lg overflow-hidden shadow-sm"
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left p-4 font-medium text-xl bg-darkGray text-lightOlive hover:bg-darkGray transition-colors duration-300 ease-in-out flex justify-between items-center rounded-lg"
          >
            <span>{item.title}</span>
            <span className="ml-2 text-xl">{activeIndex === index ? "−" : "+"}</span>
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)} // Assign the ref to the content
            className="overflow-hidden transition-[max-height] duration-500 ease-in-out"
            style={{
              maxHeight: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : "0",
            }}
          >
            <p className="p-4 bg-darkGray text-base text-lightOlive">{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
